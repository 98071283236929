/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $(document).ready(function() {
          
          // fullPage.js scroller
          $('#fullpage').fullpage({
            licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
            autoScrolling:true,
            anchors: ['intro', 'about', 'capabilities', 'facilities', 'certifications', 'contact', 'map'],
            navigation: true,
            navigationPosition: 'right',
            normalScrollElements: '.normal-scroll',
            responsiveWidth: 992,
            afterLoad: function(origin, destination, direction){
              if(destination.index===0){

              }
            },
            // events
            onLeave: function(origin, destination, direction){
              // Set Active menu class
              $('nav a[href="#'+origin.anchor+'"]').removeClass('active');
              $('nav a[href="#'+destination.anchor+'"]').addClass('active');
            },
          });

          // Mobile stuff
          $('.mobile-toggle').click(function(e) {
            e.preventDefault();
            $('.mobile nav').toggleClass('show');
          });
          $('.mobile nav a').click(function(event) {
            $('.mobile nav').removeClass('show');
            // Custom scroll type on mobile (add -100px offset for heading)
            event.preventDefault();
            if($(window).width()<992){
              var destination = $(this).attr('href');
              var sectionName = destination.substring(1);
              var section = $('.homepage-'+sectionName);
              var goToHeight = $(section).position().top - 100;
              $([document.documentElement, document.body]).animate({
                  scrollTop: goToHeight
              }, 500);
              return false;
            }
          });
          
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
